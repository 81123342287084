.recentactivities__card {
  img {
    height: 1.6rem;
    cursor: pointer;
  }
  .card-body > div:nth-of-type(odd) .bullet {
    background-color: #3699ff;
  }
  .card-body > div:nth-of-type(even) .bullet {
    background-color: #1bc5bd;
  }
  .card-header {
    color: #4f68bb;
    font-size: 1.3rem;
    font-weight: 700;
  }

  a {
    color: #7d7d7d;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 2%;
  }
}
