.activitiestodo__card {
  .card-body > div:nth-of-type(odd) .bullet {
    background-color: #3699ff;
  }
  .card-body > div:nth-of-type(even) .bullet {
    background-color: #1bc5bd;
  }

  h1 {
    color: #4f68bb;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 25px;
  }

  a {
    color: #7d7d7d;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .activitiestodo__form {
    img {
      height: 3.2rem;
    }
  }

  .activitiestodo__img-right {
    img {
      width: 2rem;
      cursor: pointer;
    }
  }
}
