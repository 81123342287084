//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

//RedVital
$primary: #1e40af;
$primary-active: #0617a8;
$rv-info: #d6e8fa;
$rv-info-light: #d6e8fa4D;
$rv-blue: #3699ff;
$rv-blue-light: #3699ff4D;
$rv-yellow: #ffc700;
$rv-yellow-light: #ffc7004D;
$rv-red: #f1416c;
$rv-red-light: #f1416c4D;
$rv-green: #4ACF29;
$rv-green-light: #4ACF294D;
$rv-success:  #4acf29;
$rv-success-light:  #4acf294D;
$rv-rejected: #E21F7D; 
$rv-rejected-light: #E21F7D4D;  
$font-family-sans-serif: 'PoppinsRegular';
$body-color: #7D7D7D;

// Contextual colors
$theme-colors: (
  'light': $light,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'dark': $dark,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'rv-blue': $rv-blue,
  'rv-red': $rv-red,
  'rv-green': $rv-green,
  'rv-yellow': $rv-yellow,
  'rv-success': $rv-success,
  'rv-rejected': $rv-rejected,
  'rv-info': $rv-info,

  );

// Contextual active state colors
$theme-active-colors: (
  'primary': $primary-active,
  'secondary': $secondary-active,
  'light': $light-active,
  'success': $success-active,
  'info': $info-active,
  'warning': $warning-active,
  'danger': $danger-active,
  'dark': $dark-active,
);

// Contextual inverse state colors
$theme-inverse-colors: (
  'primary': $primary-inverse,
  'secondary': $secondary-inverse,
  'light': $light-inverse,
  'success': $success-inverse,
  'info': $info-inverse,
  'warning': $warning-inverse,
  'danger': $danger-inverse,
  'dark': $dark-inverse,
);

// Contextual light state colors
$theme-light-colors: (
  'primary': $primary-light,
  'secondary': $secondary-light,
  'success': $success-light,
  'info': $info-light,
  'warning': $warning-light,
  'danger': $danger-light,
  'dark': $dark-light,
  'rv-blue': $rv-blue-light,
  'rv-red': $rv-red-light,
  'rv-green': $rv-green-light,
  'rv-yellow': $rv-yellow-light,
  'rv-success': $rv-success-light,
  'rv-info': $rv-info-light,
);


// Text colors
$theme-text-colors: (
  'white': $white,
  'primary': $primary,
  'secondary': $secondary,
  'light': $light,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'dark': $dark,
  'muted': $text-muted,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'rv-success': $rv-success,
  'rv-rejected': $rv-rejected,
);