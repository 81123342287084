.actionbtn__card {
  height: 12rem;
  .actionbtn__body {
    display: flex;
    justify-content: left;

    img {
      width: 3rem;
      height: 3rem;
    }
    h1 {
      margin-top: 0.75rem;
      color: #4f68bb;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}
