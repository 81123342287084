.sellproduct__card {
  max-height: 32rem;

  .card-body > div:nth-of-type(odd) .bullet {
    background-color: #3699ff;
  }
  .card-body > div:nth-of-type(even) .bullet {
    background-color: #1bc5bd;
  }
}
