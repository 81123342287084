.loader {
  max-width: 22px !important;
  max-height: 22px !important;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #1e40af;
  animation: l10 1.5s infinite linear;
}

@keyframes l10 {
  0% {
    box-shadow: 0 -30px #fff4de, calc(30px * 0.707) calc(-30px * 0.707) #eee5ff, 30px 0 #fff4de,
      0 0 #eee5ff, 0 0 #fff4de, 0 0 #eee5ff, 0 0 #fff4de, 0 0 #eee5ff;
  }

  12.5% {
    box-shadow: 0 0 #fff4de, calc(30px * 0.707) calc(-30px * 0.707) #eee5ff, 30px 0 #fff4de,
      calc(30px * 0.707) calc(30px * 0.707) #eee5ff, 0 0 #fff4de, 0 0 #eee5ff, 0 0 #fff4de,
      0 0 #eee5ff;
  }

  25% {
    box-shadow: 0 0 #c9f7f5, 0 0 #eee5ff, 30px 0 #c9f7f5,
      calc(30px * 0.707) calc(30px * 0.707) #eee5ff, 0 30px #c9f7f5, 0 0 #eee5ff, 0 0 #c9f7f5,
      0 0 #eee5ff;
  }

  37.5% {
    box-shadow: 0 0 #fff4de, 0 0 #eee5ff, 0 0 #fff4de, calc(30px * 0.707) calc(30px * 0.707) #eee5ff,
      0 30px #fff4de, calc(-30px * 0.707) calc(30px * 0.707) #eee5ff, 0 0 #fff4de, 0 0 #eee5ff;
  }

  50% {
    box-shadow: 0 0 #fff4de, 0 0 #eee5ff, 0 0 #fff4de, 0 0 #eee5ff, 0 30px #fff4de,
      calc(-30px * 0.707) calc(30px * 0.707) #eee5ff, -30px 0 #fff4de, 0 0 #eee5ff;
  }

  62.5% {
    box-shadow: 0 0 #c9f7f5, 0 0 #eee5ff, 0 0 #c9f7f5, 0 0 #eee5ff, 0 0 #c9f7f5,
      calc(-30px * 0.707) calc(30px * 0.707) #eee5ff, -30px 0 #c9f7f5,
      calc(-30px * 0.707) calc(-30px * 0.707) #eee5ff;
  }

  75% {
    box-shadow: 0 -30px #fff4de, 0 0 #eee5ff, 0 0 #fff4de, 0 0 #eee5ff, 0 0 #fff4de, 0 0 #eee5ff,
      -30px 0 #fff4de, calc(-30px * 0.707) calc(-30px * 0.707) #eee5ff;
  }

  87.5% {
    box-shadow: 0 -30px #fff4de, calc(30px * 0.707) calc(-30px * 0.707) #eee5ff, 0 0 #fff4de,
      0 0 #eee5ff, 0 0 #fff4de, 0 0 #eee5ff, 0 0 #fff4de,
      calc(-30px * 0.707) calc(-30px * 0.707) #eee5ff;
  }

  100% {
    box-shadow: 0 -30px #e1f0ff, calc(30px * 0.707) calc(-30px * 0.707) #eee5ff, 30px 0 #e1f0ff,
      0 0 #eee5ff, 0 0 #e1f0ff, 0 0 #eee5ff, 0 0 #e1f0ff, 0 0 #eee5ff;
  }
}

/* HTML: <div class="loader"></div> */
.loader-dots {
  width: 60px;
  aspect-ratio: 4;
  opacity: 75%;
  background: radial-gradient(circle closest-side, #1e40af 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

.autocomplete-filter {
  background-color: #fff4de;
}

.input-filter {
  /*  display: block;
        width: 100% !important;
        padding: 0.775rem 1rem !important;
        font-weight: 500 !important;
        color: var(--bs-gray-700) !important;
        background-color: var(--bs-body-bg);
        background-clip: padding-box;
        border: 1px solid var(--bs-gray-300) !important;
        appearance: none;
        border-radius: 0.475rem !important;
        box-shadow: false; */
  line-height: 1.5 !important;
  font-size: 1.2rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-filter:focus-visible {
  /*  border-width: 10px; */
}

.label-filter {
  display: block;
  width: 100% !important;
  padding: 1rem 1rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  color: var(--bs-black-700) !important;
  background-color: var(--bs-gray-100);
  background-clip: padding-box;
  appearance: none;
}

.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

/*Loading circular*/
.lds-ripple {
  /* change color here */
  color: #1e40af;
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
  justify-content: center;
  display: flex;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ripple div {
  position: relative;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

/*Loading Puntos*/
.lds-ellipsis {
  /* change color here */
  justify-content: center !important;
  display: flex !important;
  color: #1e40af;
  position: relative;
  width: 80px;
  height: 40px;
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}

.lds-ellipsis div {
  position: relative;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/*Aparecer  suavemente */
.fade-in-table {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Estilos para el componente de búsqueda */
/* Estilos para el componente de búsqueda */
.row-hover:hover {
  cursor: pointer;
}

.search-component {
  position: relative;
}

/* Estilos para el popup de productos */
.product-popup {
  position: absolute;
  top: calc(100%);
  left: 0;
  width: 100%;
  background-color: #f2f8fe;
  /*
        border-radius: 5px; 
        background-color: white;
        box-shadow: 0 2px 4px #0b65c0
        border: 1px solid #ccc; */
  z-index: 999;
  box-shadow: 0 1.2rem 1.5rem 0.1rem rgba(0, 0, 0, 0.075); /* Puedes ajustar los valores según tu preferencia */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.popup-content {
  max-height: 300px;
  /* Ajusta la altura máxima según sea necesario */
  overflow-y: auto;
  /* Permite desplazarse verticalmente si el contenido es demasiado largo */
}

/*   Estilos para el overlay  
.popup-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 998;
        
}

.popup-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
         
}
 */
.product-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-popup li {
  padding: 10px;
  cursor: pointer;
}

.product-popup li:hover {
  background-color: #f5f5f5;
}

.product-popup-tab {
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  tbody {
    img {
      margin: 0px 15px 0px 15px;
      height: 25px;
    }

    tr {
      background-color: #e1f0ff;
    }
  }
  .table tbody tr {
    background-clip: padding-box;
  }
  .table tbody tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .table tbody tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .table tbody tr td:first-child span {
    margin-left: 15px;
  }
}
/*Table Productos*/
.table-list-products {
  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #ffffff;
    }
    tbody {
      overflow-y: auto;

      input {
        margin: 0 0 0 5%;
      }
      img {
        margin: 0px 15px 0px 15px;
        height: 25px;
        cursor: pointer;
      }
      tr {
        background-color: #f2f8fe;
        padding: 0.3rem;
        background-clip: padding-box;
      }
      td:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
      td:first-child {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }

    .btn-action {
      display: flex;
      justify-content: center;
      span {
        justify-content: center;
        align-items: center;
        width: 90px;
        img {
          margin: 0;
          height: 100%;
          &:first-child {
            height: 1.3rem;
          }
        }
      }
    }
  }

  .modified-row-none {
    background-color: #f2f8fe;
  }
  .modified-row-up {
    background-color: #fff5f8;
  }
  .modified-row-down {
    background-color: #c9f7f5;
  }
  .discountproduct__left {
    span {
      color: #4f68bb;
      cursor: pointer;

      &:last-child {
        color: #e21f7d;
      }
    }
    img {
      height: 15px;
    }
  }

  .discountproduct__right {
    span {
      color: #e21f7d;
      cursor: pointer;
    }
  }
}

/* Disable divs*/
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed; /* optional */
}
/*  
warning : #FFF4DE
error: #EEE5FF
info: #E1F0FF
success: #C9F7F5
azulPrincipal: #1E40AF */
