.sellstore__card {
  max-height: 32rem;
  .card-body > div:nth-of-type(odd) .bullet {
    background-color: #3699ff;
  }
  .card-body > div:nth-of-type(even) .bullet {
    background-color: #1bc5bd;
  }
}

.sellstore__card {
  .sellstore__form h1 {
    border-radius: 8px;
    padding: 0.35rem;
  }
  .card-body > div:nth-of-type(even) {
    .sellstore__form h1 {
      color: #1bc5bd;
      background-color: #c9f7f5;
    }
  }

  .card-body > div:nth-of-type(odd) {
    .sellstore__form h1 {
      color: #3699ff;
      background-color: #e1f0ff;
    }
  }
}
